
import { defineComponent, reactive } from 'vue';
import { SettingOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'InputExample',
  setup() {
    const state = reactive({
      value: undefined,
      value1: 'mysite',
      value2: 'mysite',
      value3: 'Http://',
      value4: '.com',
      value5: 'mysite',
    });

    const handleSearch = (value: string) => {
      console.log('or use this.value', value, state.value);
    };

    return {
      state,

      handleSearch,
    };
  },
  components: {
    SettingOutlined,
  },
});
